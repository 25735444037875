.pointer {
  cursor: pointer;
}

input:-moz-focusring {
  border: 0;
  outline: 0;
}

.icone {
  height: 80px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #0078d9;
  border-radius: 50%;
  outline: none;
  margin-right: 5px;
}
input[type="radio"]::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  margin: 4px;
  border-radius: 50%;
  background-color: transparent;
}

input[type="radio"]:checked {
  border: 2px solid #0078d9;
  background-color: transparent;
}

input[type="radio"]:checked::before {
  background-color: #0078d9;
}
input[type="checkbox"]:checked::before {
  background-color: #0078d9;
}

.primeiroAcessoConteudo {
  height: 100%;
  overflow-y: auto;
}

.modais::-webkit-scrollbar {
  width: 12px;
}

.modais::-webkit-scrollbar-thumb {
  background-color: #d8dfe8;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.modais::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.bordaTexto {
  position: relative; /* Adicione posição relativa para permitir o posicionamento da borda */
  display: inline-block; /* Garanta que a borda fique apenas em torno do texto */
}

.bordaTexto::after {
  content: ""; /* Adicione conteúdo vazio para criar a borda */
  display: block; /* Faça com que o conteúdo vazio seja exibido como bloco */
  border-bottom: 6px solid #0078d9; /* Adicione uma borda inferior de 2 pixels sólidos pretos */
  margin-top: 0.5rem; /* Ajuste a distância entre o texto e a borda inferior conforme necessário */
  width: 45px;
}

.btn-principal {
  background: #0078d9;
  border: none;
  color: #fff;
  width: 368px !important;
  height: 70px;
  border-radius: 12px;
}
.btn-principal-Desabilitado {
  background: #d7d4d3;
  border: none;
  color: #ffffff;
  width: 368px !important;
  height: 70px;
  border-radius: 12px;
}

.btn-principal:hover {
  opacity: 0.7;
}
