.pagementoText {
  font: var(--unnamed-font-style-normal) normal 300 25px/30px Roboto;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-2f2c2c);
  text-align: left;
  font: normal normal 300 25px/30px Roboto;
  letter-spacing: 0px;
  color: #2f2c2c;
  opacity: 1;
}

.radioPagamento {
  font-size: 18px;
  font-weight: normal;
  font-family: "Questrial";
  color: #2f2c2c;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #d20a11 !important;
}

.cardPagamento {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 411px;
  height: 450px;
  /* margin: 20px 0; */
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 5px 20px #00000014;
}

.tituloCardPagamento {
  max-width: 174px;
  height: 48px;
  font: normal normal normal 18px/24px "Roboto";
}

.precoCardPagamento {
  font: normal normal normal 26px "Questrial";
}

.precoSubtituloCardPagamento {
  color: #949fac;
  font: normal normal normal 12px "Questrial";
}

.precoPorPessoaPagamento {
  color: #949fac;
  font: normal normal normal 12px "Questrial";
}

.subtotal,
.total {
  color: #2f2c2c;
  font: normal normal normal 18px/24px Roboto;
}

.subtotalValor {
  color: #2f2c2c;
  font: normal normal normal 26px/42px Questrial;
}

.totalValor {
  color: #0078d9;
  font: normal normal normal 26px/42px Questrial;
}
.form-check-input:checked {
  background-color: #0078d9 !important;
  border-color: #0078d9 !important;
}
.form-check-input[type="checkbox"] {
  cursor: pointer !important;
}
.botaoVerdeDisabled {
  text-transform: uppercase;
  height: 70px;
  font-size: 16px;
  max-width: 333px;
  font-weight: medium;
  font-family: "Roboto";
  background: #39d20a 0% 0% no-repeat padding-box;
  border: 1px solid #39d20a;
  color: #ffffff;
  border-radius: 12px;
  width: 100%;
  transition: all 0.15s ease-in-out;
  opacity: 0.5;
}

.rccs__card--front,
.rccs__card--back,
.rccs,
.rccs__card,
.rccs__card--unknown {
  width: 100% !important;
  max-width: 290px;
  height: 200px !important;
}

.mesmoCartao {
  width: 100% !important;
  max-width: 290px;
}
