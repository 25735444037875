header {
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  height: 77px;
}
.todoMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
.btnMenu {
  font-size: 14px;
  font-weight: 400;
  padding: 5px 20px 0 20px;
  font-family: Montserrat !important;
  color: #1c2d4c !important;
}
.todosBtnMenu {
  display: flex;
  align-items: center;
}
.btnMenuAtivado span {
  font-weight: 600;
  padding: 5px 0;
  border-bottom: solid 4px #40f0ed;
}
/* *{background-color: #010f210e !important;} */
.dropUsuario {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1c2d4c;
  color: #40f0ed;
  font-weight: 700;
  font-size: 20px;
  position: relative;
}
.setaDrop {
  position: absolute;
  right: -20px;
}
.pontosHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #010f21;
  height: 70px;
  width: 100%;
}
.pontosHeader span {
  font-family: Roboto;
  color: #fff;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #40f0ed;
  border-radius: 3px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #40f0ed;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  top: 0;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #f0f0f5;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.637) !important;
  z-index: 1001 !important;
}
.finalizeTelefone {
  font-weight: 400;
  font-size: 18px;
}
.cancelar {
  font: normal normal medium 16px/30px Roboto;
  color: #d20a11;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
}
.LogoChevrolet {
  max-width: 259px;
  width: 100%;
}

@media screen and (max-width: 470px) {
  .LogoChevrolet {
    display: none;
  }
}
.btnEntrarHeader {
  width: 206px;
  height: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #0078d9;
  border-radius: 12px;
}
.btnEntrarHeader-Desabilitado {
  background: #d7d4d3;
  border: none;
  color: #ffffff;
  width: 206px;
  height: 45px;
  border-radius: 12px;
}

.btnEntrarHeader:hover {
  border: 1px solid #0078d9;
  background-color: #0078d9;
  color: #fff;
  opacity: 0.85;
}

.popup {
  position: absolute;
  bottom: -80px;
  right: -180px; /* Mudança de left para right */
  width: 180px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transform: translateX(
    -100%
  ); /* Mudança de translateX(-100%) para translateX(100%) */
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  filter: drop-shadow(0 0 10px);
  border-radius: 15px;
}

.popup::before {
  content: "";
  position: absolute;
  top: -38px;
  right: 20px;
  border-width: 20px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
}

.popup span {
  cursor: pointer;
  color: #0078d9;
}
.popup span:hover {
  cursor: pointer;
  font-weight: 900;
  color: #279eff;
}
