.cadaCardHome {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  position: relative;
}

.cadaCardHomeDestaque {
  display: flex;
  flex-direction: column;
  width: 403px;
  height: 544px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 5px 20px #00000014;
}
.paddingHomeDestaque {
  padding: 0 20px 51px 20px;
  height: 100%;
}

.tituloCardHome {
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 900;
  color: #0078d9;
}
.tituloCardHomeDestaque {
  font-size: 24px;
  font-family: "Roboto";
  font-weight: normal;
  color: #ffffff;
}
.radio-container {
  border: 1px solid #ccc;
}

.btnEuQuero {
  background-color: #0078d9;
  border: 0;
  color: #000;
  padding: 10px 30px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.selected {
  border: 8px solid #0078d9;
}

.subtituloCardHome {
  font-size: 16px;
  font-family: "Roboto";
  margin-top: -13px;
}

.subtituloCardHomeDestaque {
  font-size: 16px;
  font-family: "Roboto";
  color: #b7b7b7;
  padding-right: 10px;
}
.nomeCardHome {
  font-size: 15px;
  font-weight: 500;
  width: 400px;
}
.precoCardHome {
  font-size: 20px;
  font-family: "Questrial";
  color: #0078d9;
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
}
.precoCardHomeMensalidade {
  font-size: 20px;
  font-family: "Questrial";
  color: #0078d9;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;

}

.precoSubtituloCardHome {
  font-size: 14px;
  font-family: "Questrial";
  color: #7f858d;
  white-space: nowrap;
  font-weight: 400;
}

.premioMensal {
  font-size: 14px;
  font-family: "Questrial";
  color: #949fac;
  margin-top: -10px;
}

.input-card {
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
}

.middleCard {
  background-color: #424242;
}

.sugeridoParaVoce {
  position: absolute;
  background-color: #0078d9;
  color: #fff;
  padding: 0 0 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  width: calc(100% + 20px);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-sizing: border-box;
  top: -25px;
  left: -10px;
  padding: 5px 0;
}
