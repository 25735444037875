.tituloCadastroPlano {
  font-size: 42px;
  font-family: "Questrial";
  font-weight: normal;
  color: #2f2c2c;
}
.dadosTitularCard {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 20px #949fac14;
  border-radius: 10px;
  padding: 20px;
}
.btnCadastro {
  font-size: 24px;
  font-family: "Roboto";
  font-weight: medium;
  color: #2f2c2c !important;
  cursor: default !important;
}
.btnCadastroResumo {
  font-size: 24px;
  font-family: "Roboto";
  font-weight: medium;
  padding: 0 80px;

  color: #2f2c2c !important;
  cursor: pointer !important;
}
.btnCadastroTitular {
  font-size: 24px;
  font-family: "Roboto";
  font-weight: medium;
  color: #2f2c2c !important;
  cursor: default !important;
}

.btnCadastroAtivado span {
  font-weight: 600;
  border-bottom: solid 4px #0078d9;
}
.bordaMenus {
  border-bottom: solid 1px #e3e3e3;
}

@media (max-width: 990px) {
  .btnCadastroTitular {
    padding-left: 20px;
  }
}
