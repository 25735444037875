

.loginConteudo {
  height: 100vh;
}

.btn-login {
  color: #fff !important;
  height: 70px !important;
  border-radius: 12px;
  width: 100%;
  max-width: 368px;
  background: #0078d9 !important;
  border: none !important;
}

.btn-primeiro-acesso {
  color: #2F2C2C !important;
  height: 70px !important;
  border-radius: 12px;
  border: 2px solid #2F2C2C;
  width: 100%;
  max-width: 368px;
  background: transparent !important;
  font-weight: 800;
}
.esqueciSenha {

    color: #1f88dd !important;
    font-size: "15px";
    font-weight: 500;
}
.textoMetilife {
  font: normal normal normal 15px/20px Roboto;
  color: #949fac;
}


@media screen and (max-width: 1000px) {
  .modal {
    height: 150px;
    width: 250px;
  }
}

.divText {
  width: 100%;
  background-color: blue;
}
/* .inputLogin {
  width: 368px !important;
} */