.tituloTodosPlanos h2 {
  max-width: 342px;
  letter-spacing: 1.8px;
  font-size: 36px;
  font-weight: normal;
  font-family: "Roboto";
  color: #2f2c2c;
}

.cadaCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  width: 403px;
  height: 502px;
  margin: 20px 0;
  padding: 30px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 5px 20px #00000014;
  position: relative;
}

.cadaCard.selected::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  background-color: #d20a11;
  border-radius: 50%;
}
.cadaCardDetCobertura {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 414px;
  min-height: 109px;
  margin: 20px 0;
  padding: 25px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 5px 20px #00000014;
  position: relative;
}
.cadaCardDetPeriodo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 296px;
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 5px 20px #00000014;
  position: relative;
}
.radio-periodo {
  position: absolute;
  top: 10px;
  right: 10px;
}

.tituloCardDet {
  font-size: 30px;
  font-weight: normal;
  color: #0078d9;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 50px;
  margin-top: 20px;
}
.subtituloCardDet {
  font-size: 16px;
}
.infoCardCobertura,
.infoperiodo {
  font-size: 18px;
}

.tituloCard {
  font-size: 24px;
  font-family: "Roboto";
  font-weight: normal;
  color: #2f2c2c;
}

.subtituloCard {
  font-size: 19px;
  font-family: "Roboto";
  color: #2f2c2c;
  padding-right: 10px;
}

.precoCard {
  font-size: 36px;
  font-family: "Questrial";
  color: #d20a11;
  font-weight: 500;
}

.precoSubtituloCard {
  font-size: 16px;
  font-family: "Questrial";
  color: #949fac;
  white-space: nowrap;
  font-weight: 400;
}

.precoPorPessoa {
  font-size: 16px;
  font-family: "Questrial";
  color: #949fac;
  margin-top: -10px;
}

.botaoBranco:hover {
  border: 1px solid #d20a11;
  background-color: #d20a11;
  color: #fff;
}
.input-card {
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
}
.divProtecao {
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  color: #0078d9;
  font-weight: 700;
}
.infoCardCarencia {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
