.esqueciParagrafo {
    font-size: 15px;
}

.botaoDetalheSecundario {
    width: 100% !important;
    height: 50px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    border-radius: 12px;
    color: #000000;
      font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 13px;
  }

  
.botaoDetalhe {
    width: 100% !important;
    height: 50px !important;
    background: #a3cd4d 0% 0% no-repeat padding-box;
    border: 1px solid #a3cd4d;
    border-radius: 12px;
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
  }