.tituloDados {
  font-size: 25px;
  font-weight: 300;
  font-family: "Roboto";
  color: #2f2c2c;
  margin-top: 80px;
}

.naoSeiCep a {
  color: #0078d9 !important;
  text-decoration: underline !important;
}
.modalBeneficiario {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  height: 500px;
  width: 500px;
  transform: translate(-50%, -50%);
  max-width: 900px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d8dfe8;
  border-radius: 10px;
  padding: 0;
  overflow-y: auto;
}

.botaoIncluir {
  height: 70px;
  background: #464646;
  border: 1px solid #464646;
  border-radius: 12px;
  width: 100%;
  max-width: 333px;
  color: white;
  font: normal normal medium 16px/30px Roboto;
  transition: all 0.15s ease-in-out;
}

.texto-modal-regulamento {
  font-weight: 700;
}
