.resumoTexto {
    font: normal normal normal 16px/24px Roboto;
    color: #595959;
    margin-top: 5px;
}

.tituloResumoOrientacao {
    color: #2F2C2C;
    margin-top: 80px;
    font: normal normal 300 25px/30px Roboto;
}

.subTexto {
    color: #000000;
    font: normal normal normal 14px/22px Questrial;
}

.cardResumo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 371px;
    height: 490px;
    margin: 20px 0;
    padding: 30px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 5px 20px #00000014;
  }
  
.cardResumoPlano {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 371px;
    /* height: 440px; */
    margin: 20px 0;
    padding: 30px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 5px 20px #00000014;
  }

  .detalhe {
    cursor: pointer;
  }
  .Orientacao {
    font-size: 24px;
    font-family: "Roboto";
    font-weight: normal;
    color: #2f2c2c;
  }
  
  .subtituloCard {
    font-size: 19px;
    font-family: "Roboto";
    color: #2f2c2c;
    padding-right: 10px;
  }
  
  .precoCardResumo {
    font-size: 26px;
    font-family: "Questrial";
    color: #000000;
    font-weight: 500;
  }
  
  .precoSubtituloCard {
    font-size: 16px;
    font-family: "Questrial";
    color: #949fac;
    white-space: nowrap;
    font-weight: 400;
  }
  
  .precoPorPessoa {
    font-size: 16px;
    font-family: "Questrial";
    color: #949fac;
    margin-top: -10px;
  }

  .accordionResumo {
    padding: 30px; 
    color: rgb(0, 0, 0); 
    background-color: #fcf9f7;
  }
  

.divAccordionResumo {
  width: 100%;
}
.accordion {
  width: 100%;
  padding: 0;
  margin: 0;
}

/* Other styles... */


.tituloAccordionResumo {
  background-color: rgb(245, 245, 245);
  margin-top: 10px;
}

.tituloAccordionResumo:not(.collapsed) {
  color: initial;
  background-color: rgb(245, 245, 245);
}