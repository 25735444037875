@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

@font-face {
  font-family: "ChevySans";
  src: url("./assets/font/ChevySans-Regular.otf") format("woff"); /* Substitua "sua-fonte.woff" pelo nome do seu arquivo de fonte */
  /* Substitua "sua-fonte.woff2" pelo nome do seu arquivo de fonte */
  font-weight: normal;
  font-style: normal;
}
* {
  font-family: ChevySans !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: ChevySans !important;
  background-color: #fcf9f7 !important ;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.btn-primary {
  background-color: #0078d9;
  border-radius: 0;
  border: 0;
  color: #fff;
  padding: 14px;
  border: solid 1px #0078d9;
  width: 100%;
}
.btn-outline-primary:hover,
.btn-outline-primary::selection,
.btn-outline-primary:focus,
.btn-outline-primary:active {
  background-color: #008cff !important;
  border: solid 1px #008cff;
}

.btn-outline-primary {
  background-color: transparent;
  color: #000;
  border-color: #000;
  padding: 14px;
  width: 100%;
}
.btn-outline-primary:hover,
.btn-outline-primary::selection,
.btn-outline-primary:focus,
.btn-outline-primary:active {
  border: solid 1px #000 !important;
  background-color: #008cff37 !important;
  color: #000 !important;
}
.modalInterno {
  position: relative;
  z-index: 2000;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  max-width: 500px;
  width: 100%;
  padding: 30px;
  width: 100%;
  margin: 30px;
}
.modalCloseIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.botaoBranco {
  height: 70px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 12px;
  width: 100%;
  transition: all 0.15s ease-in-out;
}

.escolherPlano {
  color: #d20a11;
  text-decoration: underline;
  cursor: pointer;
}
.accordion-button:not(.collapsed):after {
  fill: red !important;
}

/* .accordion {
  --bs-accordion-btn-active-icon: url(
    data:image/svg + xml,
    %3csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 16 16"fill="%23D20A11"%3e%3cpathfill-rule="evenodd"d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/%3e%3c/svg%3e
  ) !important;
} */

.accordion-button:not(.collapsed)::after {
  transform: rotate(0deg) !important;
}
.accordion-button::after {
  color: red !important;
  transform: rotate(-90deg) !important;
}
.botaoVerde {
  text-transform: uppercase;
  height: 70px;
  font-size: 16px;
  max-width: 333px;
  font-weight: medium;
  font-family: "Roboto";
  background: #39d20a 0% 0% no-repeat padding-box;
  border: 1px solid #39d20a;
  color: #ffffff;
  border-radius: 12px;
  width: 100%;
  transition: all 0.15s ease-in-out;
}

.botaoVermelho {
  height: 60px;
  background: #d20a11;
  border: 1px solid #d20a11;
  border-radius: 12px;
  width: 100%;
  max-width: 849px;
  color: white;
  font: normal normal medium 16px/30px "Roboto";
  transition: all 0.15s ease-in-out;
}

.botaoVoltar {
  height: 70px;
  width: 100%; /* alterado de 333px para 100% */
  max-width: 333px;
  background: #fafafa;
  border: 1px solid #000000;
  border-radius: 12px;
}

.row {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none !important;
  list-style: none !important;
  color: #000 !important;
}
li,
ul {
  text-decoration: none !important;
  list-style-type: none !important;
}
textarea:focus,
input:focus,
select:focus {
  box-shadow: none !important;
}

* {
  /* background-color: rgba(0, 0, 255, 0.144) !important; */
}
input[type="password"] {
  padding-right: 50px !important;
}
input:disabled {
  font-weight: 900;
}

.configModal {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border: 1px solid #000;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.801);
  padding: 32px;
}

.btn-secondary {
  border-radius: 0;
  border: 0;
  font-weight: 500;
  font-size: 15px;
  padding: 14px;
  border: solid 1px;
}

.btn-secondary:hover,
.btn-secondary:active {
  filter: grayscale(0.5);
  border: solid 1px;
}

.btn-warning {
  border-radius: 0;
  border: 0;
  color: #0c2a4e;
  font-weight: 500;
  font-size: 15px;
  padding: 14px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination {
  /* margin-top: 5px; */
  /* position: initial !important; */
}
.swiper-pagination-bullet {
  opacity: 1 !important;
  background: #fafafa !important;
  background-color: #fafafa88 !important;
  width: 10px !important;
  height: 10px !important;
  margin: 0 10px !important;
}
.swiper-pagination-bullet-active {
  background: #0078d9 !important;
  scale: 1.3;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: #fff !important;
  font-size: 20px !important;
}
.btn {
  border-radius: 0;
}
.modalExterno {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.589);
  height: 100vh !important;
  width: 100%;
}
