.footer {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.metilifeTextFooter {
  color: #212121;
  font: normal normal normal 18px/24px Roboto;
}

.textoFooter {
  font-size: 18px;
  font-family: "Roboto";
  font-weight: normal;
  color: #595959;
}

.metilifeImgFooter {
  width: 163.96px;
}
.imgIbama {
  width: 42px;
  height: 48px;
}
.suporte,
.sobreNos,
.contato {
  font-weight: normal;
  font-size: 18px;
  color: #2f2c2c;
  text-transform: uppercase;
}

p,
li {
  margin-top: 10px;
  font-weight: normal;
  font-size: 18px;
}

.linkFooter {
  color: #0078d9 !important;
  text-decoration: underline !important;
}
